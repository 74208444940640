import { mapState } from 'vuex'

export const setBankAccount = {
  computed: {
    ...mapState([
      'supportedBankTypes'
    ])
  },
  methods: {
    setBankAccount (role, data) {
      this.document[role] = JSON.parse(JSON.stringify(data))
    },
    populateBankAccounts () {
      this.supportedBankTypes.map(bankAccountCategory => {
        if (this.source.document[bankAccountCategory]) {
          const bankAccount = this.myBankAccounts.find(
            account => account.card.account === this.source.document[bankAccountCategory].account
          )
          if (bankAccount) {
            this.setBankAccount(bankAccountCategory, bankAccount)
          }
        }
      })
    }
  },
  watch: {
    myBankAccounts: {
      immediate: true,
      handler: function () {
        if (this.isCopyOrFlip) this.populateBankAccounts()
      }
    }
  }
}
