<template>
  <v-col
    cols="12"
    sm="6"
    md="3"
  >
    <custom-select
      v-model="localBank"
      outlined
      :items="items"
      item-text="acronym"
      return-object
      :label="$t('documents.create.properties.supplierBank')"
      data-cy="document-bank"
    >
      <template v-slot:append-item>
        <v-divider />
        <v-list-item>
          <v-list-item-content>
            <v-btn
              color="primary"
              outlined
              @click="$bus.emit('open:bank-create-dialog')"
            >
              <v-icon left>
                {{ $vuetify.icons.values.add }}
              </v-icon>
              {{ $t('general.add') }}
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </template>
    </custom-select>
    <bank-card
      v-if="localBank.card"
      :card="localBank.card"
    />
  </v-col>
</template>

<script>
import BankCard from '@/components/create/cards/BankCard'
import CustomSelect from '@/components/inputs/CustomSelect'

export default {
  name: 'PartnerAutocomplete',
  components: {
    BankCard,
    CustomSelect
  },
  props: {
    items: { type: Array, default: () => { return [] } },
    bankData: { type: Object, default: () => { return {} } },
    label: { type: String, default: '' },
    rules: { type: Array, default: () => { return [] } },
    clearable: { type: Boolean, default: true }
  },
  computed: {
    localBank: {
      get () {
        return this.bankData
      },
      set (value) {
        this.$emit('update:bank-data', value || {})
      }
    }
  }
}
</script>
