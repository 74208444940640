export const paymentDate = {
  data () {
    return {
      paymentDateMethod: 'paymentPeriod'
    }
  },
  computed: {
    paymentDateMethodsList () {
      return [
        { value: 'paymentPeriod', text: this.$t('documents.create.paymentPeriod') },
        { value: 'dueDate', text: this.$t('general.date') }
      ]
    }
  },
  methods: {
    setPaymentDateMethod () {
      this.dueDate
        ? this.paymentDateMethod = 'dueDate'
        : this.paymentDateMethod = 'paymentPeriod'
    }
  },
  watch: {
    paymentDateMethod (value) {
      value === 'paymentPeriod'
        ? this.$emit('update:due-date', null)
        : this.$emit('update:payment-period', null)
    },
    dueDate: {
      immediate: true,
      handler () {
        this.setPaymentDateMethod()
      }
    }
  }
}
