import { generateStandardInvoice } from '@/mapping/invoice'
import { getApiDocumentCategory } from '@/mapping/utils'
import api from '@/api/modules/documents'

export const submitInvoice = {
  methods: {
    setErrorState (pipeline, recipientsAreValid) {
      this.submitDisabled = false
      this.submitColor = 'error'
      this.$emit('update:invalid-form-warning', true)
      this.$emit('update:pipeline-error', !pipeline)
      this.$emit('update:recipients-error', !recipientsAreValid)
    },
    validateFormData () {
      return this.$refs.form.validate() &&
      this.pipeline &&
      this.recipientsAreValid()
    },
    indexDocumentLines (linesArray) {
      return linesArray.map((item, index) => ({
        lineNumber: index + 1,
        ...item
      }))
    },
    async submit () {
      this.submitDisabled = true
      if (this.validateFormData()) {
        try {
          this.submitColor = 'primary'
          this.document.isExonerationApplicable = this.showExemptionReason
          this.document.lines = this.indexDocumentLines(this.document.lines)

          const mappedData = generateStandardInvoice(
            this.documentCategory,
            this.recipients,
            this.isPushEmail,
            this.language,
            this.supplier,
            this.buyer,
            this.orderedBy,
            this.deliverTo,
            this.document
          )

          const file = new Blob([mappedData], { type: 'application/json' })

          const form = new FormData()
          form.append('pipeline', this.pipeline)
          form.append('category', getApiDocumentCategory(this.documentCategory))
          form.append('number', this.document.documentNumber)
          form.append('date', this.document.documentDate)
          form.append('document', file)

          await api.createDocument(form)
        } catch (error) {
          this.setErrorState(this.pipeline, this.recipientsAreValid())
        } finally {
          this.$bus.emit('created:document')
        }
      } else {
        this.setErrorState(this.pipeline, this.recipientsAreValid())
      }
      this.submitDisabled = false
    }
  }
}
