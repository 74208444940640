<template>
  <v-col
    v-if="showExemptionReason"
    cols="12"
  >
    <p class="text-h6 font-weight-regular">
      {{ $t('documents.create.exemption') }}
    </p>
    <v-row>
      <v-col cols="12">
        <custom-text-field
          name="createinv-exemption"
          clearable
          :value="exonerationReason"
          type="text"
          :label="$t('documents.create.reason') + conditionalAsterisk"
          :placeholder="$t('documents.create.commentLabel')"
          :rules="[rules.required]"
          counter="128"
          maxlength="128"
          data-cy="document-exemption-reason"
          @input="$emit('update:exoneration-reason', $event)"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import CustomTextField from '@/components/inputs/CustomTextField'

export default {
  name: 'ExepmtionReason',
  components: {
    CustomTextField
  },
  props: {
    rules: { type: Object, default: () => { return {} } },
    conditionalAsterisk: { type: String, default: '' },
    showExemptionReason: { type: Boolean, default: false },
    exonerationReason: { type: String, default: '' }
  }
}
</script>
