<template>
  <v-col cols="12">
    <p class="text-h6 font-weight-regular">
      {{ $t('documents.create.vatSummary.title') }}
    </p>
    <v-data-iterator
      :items="vatSummaryItems"
      no-data-text=""
      hide-default-footer
      disable-pagination
      class="row"
    >
      <template v-slot:item="props">
        <v-col
          cols="12"
          class="pa-0"
        >
          <v-avatar>
            <span class="text-h5">{{ getVatSummaryItemIndex(props.item) + 1 }}</span>
          </v-avatar>
        </v-col>
        <v-col
          cols="12"
          md="6"
          data-cy="vat-summary"
        >
          <custom-textarea
            v-model="props.item.description"
            name="description"
            :label="$t('documents.create.vatSummary.comment')"
            :placeholder="$t('documents.create.commentLabel')"
            auto-grow
            rows="1"
            data-cy="vat-summary-comment"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
          md="2"
        >
          <custom-text-field
            :value="props.item.percent | twoDecimals"
            readonly
            :hint="$t('general.calculated')"
            persistent-hint
            :label="$t('documents.create.vatSummary.percent')"
            data-cy="vat-summary-percent"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
          md="2"
        >
          <custom-text-field
            :value="props.item.taxableAmount | twoDecimals"
            readonly
            :hint="$t('general.calculated')"
            persistent-hint
            :label="$t('documents.create.vatSummary.taxable')"
            data-cy="vat-summary-taxable"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
          md="2"
        >
          <custom-text-field
            :value="props.item.amount | twoDecimals"
            readonly
            :hint="$t('general.calculated')"
            persistent-hint
            :label="$t('documents.create.vatSummary.amount')"
            data-cy="vat-summary-amount"
          />
        </v-col>
      </template>
    </v-data-iterator>
  </v-col>
</template>

<script>
import CustomTextarea from '@/components/inputs/CustomTextarea'
import CustomTextField from '@/components/inputs/CustomTextField'

export default {
  name: 'VatSummary',
  components: {
    CustomTextarea,
    CustomTextField
  },
  props: {
    vatSummaryItems: { type: Array, default: () => { return [] } },
    lineItems: { type: Array, default: () => { return [] } }
  },
  watch: {
    lineItems: {
      deep: true,
      handler: function () {
        this.updateSummary()
      }
    }
  },
  methods: {
    getVatSummaryItemIndex (item) {
      return this.vatSummaryItems.indexOf(item)
    },
    getSummaryItemDesc (percent) {
      return this.vatSummaryItems.find(item => item.percent === percent)
        ? this.vatSummaryItems.find(item => item.percent === percent).description
        : null
    },
    getSummaryLine (acc, percent) {
      return acc.find(entry => entry.percent === percent)
    },
    getRoundedSummaryArray (vatSummaryArray) {
      return vatSummaryArray.map(summaryLine => {
        return {
          ...summaryLine,
          taxableAmount: this.$roundTo(summaryLine.taxableAmount, 2),
          amount: this.$roundTo(summaryLine.amount, 2)
        }
      })
    },
    updateSummary () {
      const vatSummaryArray = this.lineItems.reduce((acc, curr) => {
        const unitPrice = this.$getNumber(curr.unitPrice)
        const quantity = this.$getNumber(curr.quantity)
        const percent = this.$getNumber(curr.vat.percent)
        const desc = this.getSummaryItemDesc(percent)
        const accSummary = this.getSummaryLine(acc, percent)
        if (accSummary) {
          accSummary.taxableAmount += quantity * unitPrice
          accSummary.amount += quantity * unitPrice * (percent / 100)
          accSummary.description = desc
        } else {
          acc.push({
            taxableAmount: quantity * unitPrice,
            percent: percent,
            amount: quantity * unitPrice * (percent / 100),
            description: desc
          })
        }
        return acc
      }, [])
      const roundedSummaryArray = this.getRoundedSummaryArray(vatSummaryArray)
      this.$emit('update:vat-summary-items', roundedSummaryArray)
    }
  }
}
</script>
