import {
  getEntityCardData,
  getIsAttachedPDF,
  getIsPushEmail,
  cleanEmptyKeys
} from '@/mapping/utils'

export function generateStandardInvoice (
  documentCategory,
  recipients,
  isPushEmail,
  language,
  supplier,
  buyer,
  orderedBy,
  deliverTo,
  document
) {
  const transaction = {
    treatment: {
      documentCategory: documentCategory,
      recipients: recipients,
      isPushEmail: getIsPushEmail(isPushEmail),
      isAttachedPDF: getIsAttachedPDF(isPushEmail),
      language: language,
      isDematerialized: false,
      isSharedDemat: false,
      isIntegrated: false,
      purpose: 'duplicate'
    },
    supplier: getEntityCardData(supplier),
    buyer: getEntityCardData(buyer),
    orderedBy: getEntityCardData(orderedBy),
    deliverTo: getEntityCardData(deliverTo),
    document: {
      documentNumber: document.documentNumber,
      documentDate: document.documentDate,
      isExonerationApplicable: document.isExonerationApplicable,
      exonerationReason: document.exonerationReason,
      currencyCode: document.currencyCode,
      purchaseOrderNumber: document.purchaseOrderNumber,
      purchaseOrderDate: document.purchaseOrderDate,
      deliveryNoteNumber: document.deliveryNoteNumber,
      deliveryNoteDate: document.deliveryNoteDate,
      comment: document.comment,
      references: document.references,
      lines: document.lines,
      vatSummary: document.vatSummary,
      totalNetAmount: document.totalNetAmount,
      totalVatAmount: document.totalVatAmount,
      totalGrossAmount: document.totalGrossAmount,
      latePayment: document.latePayment,
      earlyPayment: document.earlyPayment,
      paymentRemaining: document.paymentRemaining,
      supplierBank: getEntityCardData(document.supplierBank)
    }
  }

  if (documentCategory === 'creditNote' || documentCategory === 'debitNote') {
    transaction.document.invoiceNumber = document.invoiceNumber
    transaction.document.invoiceDate = document.invoiceDate
  }

  return JSON.stringify(transaction, cleanEmptyKeys)
}
