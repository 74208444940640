<template>
  <v-card
    v-if="card"
    outlined
  >
    <v-card-text class="text--primary">
      <h1
        v-if="card.bankName"
        class="text-body-1"
      >
        <span class="text-body-2">{{ $t('documents.create.bank.bankName') }}: </span>
        {{ card.bankName }}
      </h1>
      <h1
        v-if="card.bankBranchName"
        class="text-body-1"
      >
        <span class="text-body-2">{{ $t('documents.create.bank.bankBranchName') }}: </span>
        {{ card.bankBranchName }}
      </h1>
      <h1
        v-if="card.bankCountry"
        class="text-body-1"
      >
        <span class="text-body-2">{{ $t('documents.create.bank.bankCountry') }}: </span>
        {{ card.bankCountry }}
      </h1>
      <h1
        v-if="card.bic"
        class="text-body-1"
      >
        <span class="text-body-2">{{ $t('documents.create.bank.bic') }}: </span>
        {{ card.bic }}
      </h1>
      <h1
        v-if="card.iban"
        class="text-body-1"
      >
        <span class="text-body-2">{{ $t('documents.create.bank.iban') }}: </span>
        {{ card.iban }}
      </h1>
      <h1
        v-if="card.account"
        class="text-body-1"
      >
        <span class="text-body-2">{{ $t('documents.create.bank.account') }}: </span>
        {{ card.account }}
      </h1>
      <h1
        v-if="card.beneficieryName"
        class="text-body-1"
      >
        <span class="text-body-2">{{ $t('documents.create.bank.beneficieryName') }}: </span>
        {{ card.beneficieryName }}
      </h1>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BankCard',
  props: {
    card: { type: Object, default: () => { return {} } }
  }
}
</script>
