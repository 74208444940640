<template>
  <v-menu
    :ref="reference"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :return-value.sync="localDate"
  >
    <template v-slot:activator="{ on }">
      <custom-text-field
        ref="invRefDate"
        v-model="localDate"
        :prepend-icon="datePickerProps.icon"
        clearable
        readonly
        :label="$t(datePickerProps.label) + asterisk"
        :placeholder="$t('general.select')"
        :rules="[invoiceDateRules]"
        :hint="$t('documents.create.invoiceRefOrDateRule')"
        persistent-hint
        :data-cy="dataCy"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="localDate"
      first-day-of-week="1"
      scrollable
      :landscape="$vuetify.breakpoint.mdAndUp"
      :locale="locale"
    >
      <v-spacer />
      <v-btn
        text
        rounded
        color="primary"
        @click="menu = false"
      >
        {{ $t('general.close') }}
      </v-btn>
      <v-btn
        text
        rounded
        color="primary"
        data-cy="date-save"
        @click="$refs[reference].save(date)"
      >
        {{ $t('general.save') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import CustomTextField from '@/components/inputs/CustomTextField'

export default {
  name: 'InvoiceDate',
  components: {
    CustomTextField
  },
  props: {
    datePickerProps: { type: Object, default: () => { return {} } },
    reference: { type: String, default: '' },
    date: { type: String, default: '' },
    invoiceNumber: { type: String, default: '' },
    dataCy: { type: String, default: 'date' }
  },
  data () {
    return {
      menu: false,
      invoiceDateRules: value => !!value || this.invoiceNumber ? true : this.$t('general.rules.required')
    }
  },
  computed: {
    locale () {
      return this.$i18n.locale
    },
    localDate: {
      get () {
        return this.date
      },
      set (value) {
        this.$emit('update:date', value)
      }
    },
    asterisk () {
      return this.invoiceNumber
        ? ''
        : ' *'
    }
  },
  watch: {
    invoiceNumber (value) {
      if (value) {
        this.$refs.invRefDate.validate()
      }
    }
  }
}
</script>
