export const invoiceConditionalAsterisk = {
  computed: {
    conditionalAsterisk () {
      return this.documentCategory !== 'proformInvoice' ? ' *' : ''
    }
  }
}

export const paymentMethods = {
  computed: {
    paymentMethods () {
      return this.getSupportedPaymentMethods.map(method => {
        return { value: method, text: this.$t('paymentMethods.' + method) }
      })
    }
  }
}

export const showExemptionReason = {
  computed: {
    showExemptionReason () {
      return this.document.vatSummary.some(tax => tax.percent === 0)
    }
  }
}
