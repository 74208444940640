export const invoiceDataTemplate = {
  data () {
    return {
      document: {
        documentNumber: null,
        documentDate: null,
        isExonerationApplicable: true,
        exonerationReason: null,
        currencyCode: null,
        invoiceNumber: null,
        invoiceDate: null,
        purchaseOrderNumber: null,
        purchaseOrderDate: null,
        deliveryNoteNumber: null,
        deliveryNoteDate: null,
        comment: null,
        references: [],
        lines: [],
        vatSummary: [],
        totalNetAmount: 0,
        totalVatAmount: 0,
        totalGrossAmount: 0,
        latePayment: {
          comment: null
        },
        earlyPayment: {
          comment: null
        },
        paymentRemaining: {
          date: null,
          paymentPeriod: null,
          amount: 0,
          paymentMethodType: 'bankTransfer'
        },
        supplierBank: {}
      }
    }
  }
}
