<template>
  <p class="text-h6 font-weight-regular">
    {{ $t('documents.create.bank.title') }}
  </p>
</template>

<script>
export default {
  name: 'BanksHeadline'
}
</script>
