<template>
  <v-col
    cols="12"
  >
    <v-row>
      <v-col
        class="justify-center"
        cols="12"
        sm="3"
      >
        <client-logo />
      </v-col>
      <v-col
        cols="12"
        sm="9"
      >
        <v-row
          align="start"
          justify="start"
        >
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <custom-text-field
              :value="documentNumber"
              :label="$t('documentCategories.' + documentCategory) + ' *'"
              :placeholder="$t('documents.create.numberLabel')"
              clearable
              :rules="[rules.alwaysRequired]"
              counter="128"
              maxlength="128"
              data-cy="document-number"
              @input="$emit('update:document-number', $event)"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <date-picker
              :date-picker-props="dateProps['document']"
              :date="documentDate"
              :min="$dateInThePast(31)"
              :rules="rules"
              reference="documentDate"
              data-cy="document-date"
              @update:date="$emit('update:document-date', $event)"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <custom-autocomplete
              :value="currencyCode"
              :items="currencies"
              :label="$t('documents.create.currency') + ' *'"
              :placeholder="$t('documents.create.currencyLabel')"
              clearable
              auto-select-first
              :no-data-text="$t('documents.create.currencyNotFound')"
              persistent-hint
              :rules="[rules.alwaysRequired]"
              data-cy="document-currency"
              @input="$emit('update:currency-code', $event)"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <custom-select
              :items="paymentMethods"
              :value="paymentMethodType"
              :label="$t('documents.create.paymentMethodType') + conditionalAsterisk"
              :placeholder="$t('general.select')"
              clearable
              :rules="[rules.required]"
              required
              @input="$emit('update:payment-method-type', $event)"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <custom-select
              v-model="paymentDateMethod"
              :items="paymentDateMethodsList"
              :label="$t('documents.create.properties.paymentDateMethod') + ' *'"
              :rules="[rules.alwaysRequired]"
              required
            />
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <custom-text-field
              v-if="paymentDateMethod === 'paymentPeriod'"
              name="createinv-paymentperiod"
              :value="paymentPeriod"
              :label="$t('documents.create.paymentPeriod') + conditionalAsterisk"
              :placeholder="$t('documents.create.commentLabel')"
              clearable
              :rules="[rules.required]"
              counter="128"
              maxlength="128"
              data-cy="document-payment-period"
              @input="$emit('update:payment-period', $event)"
            />
            <date-picker
              v-else
              :date-picker-props="dateProps['dueDate']"
              :date="dueDate"
              :rules="rules"
              reference="date"
              @update:date="$emit('update:due-date', $event)"
            />
          </v-col>
          <v-col cols="12">
            <custom-textarea
              auto-grow
              rows="1"
              clearable
              :value="comment"
              type="text"
              :label="$t('documents.create.comment')"
              :placeholder="$t('documents.create.commentLabel')"
              data-cy="document-comment"
              @input="$emit('update:comment', $event)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import ClientLogo from '@/components/logo/ClientLogo'
import DatePicker from '@/components/create/Date'
import { currencyList } from '@/mixins/create/currencies'
import { paymentMethods } from '@/mixins/create/computed'
import { paymentDate } from '@/mixins/create/paymentDateMethod'
import CustomTextField from '@/components/inputs/CustomTextField'
import CustomSelect from '@/components/inputs/CustomSelect'
import CustomAutocomplete from '@/components/inputs/CustomAutocomplete'
import CustomTextarea from '@/components/inputs/CustomTextarea'

export default {
  name: 'InvoiceHeader',
  components: {
    ClientLogo,
    DatePicker,
    CustomTextField,
    CustomSelect,
    CustomAutocomplete,
    CustomTextarea
  },
  mixins: [
    currencyList,
    paymentMethods,
    paymentDate
  ],
  props: {
    documentCategory: { type: String, default: '' },
    rules: { type: Object, default: () => { return {} } },
    dateProps: { type: Object, default: () => { return {} } },
    conditionalAsterisk: { type: String, default: '' },
    documentNumber: { type: String, default: '' },
    documentDate: { type: String, default: '' },
    currencyCode: { type: String, default: '' },
    paymentMethodType: { type: String, default: '' },
    paymentPeriod: { type: String, default: '' },
    dueDate: { type: String, default: '' },
    comment: { type: String, default: '' }
  },
  computed: {
    ...mapGetters([
      'getSupportedPaymentMethods'
    ])
  }
}
</script>
