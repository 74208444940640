<template>
  <v-form
    ref="form"
    v-model="form.valid"
    @submit.prevent="submit"
  >
    <card-layout
      content-class="text--primary"
    >
      <template v-slot:title>
        {{ $t('documentCategories.' + documentCategory) }}
      </template>
      <template v-slot:content>
        <v-row>
          <invoice-header
            :document-category="documentCategory"
            :rules="rules"
            :date-props="dateProps"
            :conditional-asterisk="conditionalAsterisk"
            :document-number.sync="document.documentNumber"
            :document-date.sync="document.documentDate"
            :currency-code.sync="document.currencyCode"
            :payment-method-type.sync="document.paymentRemaining.paymentMethodType"
            :payment-period.sync="document.paymentRemaining.paymentPeriod"
            :due-date.sync="document.paymentRemaining.date"
            :comment.sync="document.comment"
          />
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col cols="12">
            <partners-headline />
            <v-row>
              <partner-autocomplete
                :items="myCompanies"
                :partner-data.sync="supplier"
                :label="$t('documents.create.properties.sender') + ' *'"
                :rules="[rules.alwaysRequired]"
                :clearable="false"
              />
              <partner-autocomplete
                :items="clientCompanies"
                :partner-data.sync="buyer"
                :label="$t('documents.create.properties.receiver') + ' *'"
                :rules="[rules.alwaysRequired]"
                :clearable="false"
              />
              <partner-autocomplete
                :items="clientCompanies"
                :partner-data.sync="deliverTo"
                :label="$t('documents.create.properties.deliverTo')"
              />
              <partner-autocomplete
                :items="clientCompanies"
                :partner-data.sync="orderedBy"
                :label="$t('documents.create.properties.orderedBy')"
              />
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <v-col cols="12">
            <banks-headline />
            <v-row>
              <bank-autocomplete
                :items="myBankAccounts"
                :bank-data.sync="document.supplierBank"
                :label="$t('documents.create.properties.supplierBank')"
              />
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <invoice-references
            :rules="rules"
            :date-props="dateProps"
            :invoice-ref-is-required="invoiceRefIsRequired"
            :conditional-asterisk="conditionalAsterisk"
            :purchase-order-number.sync="document.purchaseOrderNumber"
            :purchase-order-date.sync="document.purchaseOrderDate"
            :invoice-number.sync="document.invoiceNumber"
            :invoice-date.sync="document.invoiceDate"
          />
          <v-col cols="12">
            <v-divider />
          </v-col>
          <references-group
            :items.sync="document.references"
            :rules="rules"
          />
          <v-col cols="12">
            <v-divider />
          </v-col>
          <lines-group
            :items="document.lines"
            :line-template="lineTemplate"
          >
            <template v-slot:inputs="{ item }">
              <invoice-lines-inputs
                :item="item"
                :rules="rules"
                :selected-document-category="documentCategory"
              />
            </template>
          </lines-group>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <payment-terms
            :early-payment.sync="document.earlyPayment"
            :late-payment.sync="document.latePayment"
          />
          <v-col cols="12">
            <v-divider />
          </v-col>
          <exemption-reason
            :rules="rules"
            :conditional-asterisk="conditionalAsterisk"
            :show-exemption-reason="showExemptionReason"
            :exoneration-reason.sync="document.exonerationReason"
          />
          <v-col
            v-if="showExemptionReason"
            cols="12"
          >
            <v-divider />
          </v-col>
          <vat-summary
            :vat-summary-items.sync="document.vatSummary"
            :line-items="document.lines"
          />
          <v-col cols="12">
            <v-divider />
          </v-col>
          <totals
            :line-items="document.lines"
            :total-net-amount.sync="document.totalNetAmount"
            :total-vat-amount.sync="document.totalVatAmount"
            :total-gross-amount.sync="document.totalGrossAmount"
            :payment-remaining.sync="document.paymentRemaining.amount"
          />
          <submit-button
            :submit-disabled="submitDisabled"
            :submit-color="submitColor"
          />
        </v-row>
      </template>
    </card-layout>
  </v-form>
</template>

<script>
import { commonCreateProps } from '@/mixins/create/common'
import { invoiceDataTemplate } from '@/mixins/create/invoiceDataTemplate'
import {
  supplier,
  buyer,
  deliverTo,
  orderedBy,
  supplierBankAccount
} from '@/mixins/create/documentEntitiesTemplate'
import { setPartner } from '@/mixins/create/setPartner'
import { setBankAccount } from '@/mixins/create/setBankAccount'
import {
  references,
  invoiceRefs,
  invoiceLineTemplate
} from '@/mixins/create/createDocument'
import {
  invoiceConditionalAsterisk,
  showExemptionReason
} from '@/mixins/create/computed'
import {
  recipientsAreValid
} from '@/mixins/create/methods'
import { submitInvoice } from '@/mixins/create/submitInvoice'
import { validateEmail } from '@/mixins/validation'
import CardLayout from '@/components/layout/CardLayout'
import InvoiceHeader from '@/components/create/inputs/headers/InvoiceHeader'
import PartnersHeadline from '@/components/create/headlines/PartnersHeadline'
import PartnerAutocomplete from '@/components/create/inputs/Partner'
import BanksHeadline from '@/components/create/headlines/BanksHeadline'
import BankAutocomplete from '@/components/create/inputs/Bank'
import InvoiceReferences from '@/components/create/inputs/refs/InvoiceReferences'
import ReferencesGroup from '@/components/create/inputs/ReferencesGroup'
import LinesGroup from '@/components/create/inputs/lines/LinesGroup'
import InvoiceLinesInputs from '@/components/create/inputs/lines/InvoiceInputs'
import PaymentTerms from '@/components/create/inputs/PaymentTerms'
import ExemptionReason from '@/components/create/inputs/ExemptionReason'
import VatSummary from '@/components/create/inputs/VatSummary'
import Totals from '@/components/create/inputs/Totals'
import SubmitButton from '@/components/buttons/Submit'

export default {
  name: 'Invoice',
  components: {
    InvoiceHeader,
    PartnersHeadline,
    PartnerAutocomplete,
    BanksHeadline,
    BankAutocomplete,
    InvoiceReferences,
    ReferencesGroup,
    LinesGroup,
    InvoiceLinesInputs,
    PaymentTerms,
    ExemptionReason,
    VatSummary,
    Totals,
    SubmitButton,
    CardLayout
  },
  mixins: [
    commonCreateProps,
    invoiceDataTemplate,
    supplier,
    buyer,
    deliverTo,
    orderedBy,
    supplierBankAccount,
    setPartner,
    setBankAccount,
    references,
    invoiceRefs,
    invoiceLineTemplate,
    invoiceConditionalAsterisk,
    showExemptionReason,
    recipientsAreValid,
    submitInvoice,
    validateEmail
  ],
  watch: {
    myCompanies: {
      immediate: true,
      handler: function () {
        if (this.myCompanies.length === 1) {
          this.setPartner('supplier', this.myCompanies[0])
        }
      }
    },
    clientCompanies: {
      immediate: true,
      handler: function () {
        if (this.clientCompanies.length === 1) {
          this.setPartner('buyer', this.clientCompanies[0])
        }
      }
    },
    myBankAccounts: {
      immediate: true,
      handler: function () {
        if (this.myBankAccounts.length === 1) {
          this.setBankAccount('supplierBank', this.myBankAccounts[0])
        }
      }
    }
  }
}
</script>
