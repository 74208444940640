<template>
  <v-col cols="12">
    <p class="text-h6 font-weight-regular">
      {{ $t('documents.create.paymentTermsTitle') }}
    </p>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <custom-text-field
          v-model="earlyPayment.comment"
          name="createinv-earlypayment"
          clearable
          type="text"
          :label="$t('documents.create.earlyPayment')"
          :placeholder="$t('documents.create.commentLabel')"
          counter="128"
          maxlength="128"
          data-cy="document-early-payment-comment"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <custom-text-field
          v-model="latePayment.comment"
          name="createinv-latepayment"
          clearable
          type="text"
          :label="$t('documents.create.latePayment')"
          :placeholder="$t('documents.create.commentLabel')"
          counter="128"
          maxlength="128"
          data-cy="document-late-payment-comment"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import CustomTextField from '@/components/inputs/CustomTextField'

export default {
  name: 'PaymentTerms',
  components: {
    CustomTextField
  },
  props: {
    earlyPayment: { type: Object, default: () => { return {} } },
    latePayment: { type: Object, default: () => { return {} } }
  }
}
</script>
