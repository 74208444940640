<template>
  <v-col cols="12">
    <p class="text-h6 font-weight-regular">
      {{ $t('documents.create.refs.title') }}
    </p>
    <v-row>
      <v-col
        v-if="invoiceRefIsRequired"
        cols="12"
        sm="6"
        lg="3"
      >
        <custom-text-field
          ref="invoiceNumber"
          :value="invoiceNumber"
          clearable
          :rules="[invoiceNumberRules]"
          :label="$t('documents.create.refs.invoice') + invoiceReferenceAsterisk"
          :placeholder="$t('documents.create.numberLabel')"
          :hint="$t('documents.create.invoiceRefOrDateRule')"
          persistent-hint
          counter="128"
          maxlength="128"
          data-cy="invoice-reference"
          @input="$emit('update:invoice-number', $event)"
        />
      </v-col>
      <v-col
        v-if="invoiceRefIsRequired"
        cols="12"
        sm="6"
        lg="3"
      >
        <invoice-date
          :date-picker-props="dateProps['invoice']"
          :date="invoiceDate"
          reference="invoiceDate"
          :invoice-number="invoiceNumber"
          data-cy="invoice-reference-date"
          @update:date="$emit('update:invoice-date', $event)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <custom-text-field
          :value="purchaseOrderNumber"
          clearable
          :label="$t('documents.create.refs.purchaseOrder')"
          :placeholder="$t('documents.create.numberLabel')"
          counter="128"
          maxlength="128"
          data-cy="po-reference"
          @input="$emit('update:purchase-order-number', $event)"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <date-picker
          :date-picker-props="dateProps['purchaseOrder']"
          :date="purchaseOrderDate"
          :rules="rules"
          reference="purchaseOrderDate"
          data-cy="po-reference-date"
          @update:date="$emit('update:purchase-order-date', $event)"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import InvoiceDate from '@/components/create/inputs/refs/InvoiceDate'
import DatePicker from '@/components/create/Date'
import CustomTextField from '@/components/inputs/CustomTextField'

export default {
  name: 'InvoiceReferences',
  components: {
    InvoiceDate,
    DatePicker,
    CustomTextField
  },
  props: {
    rules: { type: Object, default: () => { return {} } },
    dateProps: { type: Object, default: () => { return {} } },
    invoiceRefIsRequired: { type: Boolean, default: false },
    conditionalAsterisk: { type: String, default: '' },
    purchaseOrderNumber: { type: String, default: '' },
    purchaseOrderDate: { type: String, default: '' },
    invoiceNumber: { type: String, default: '' },
    invoiceDate: { type: String, default: '' }
  },
  data () {
    return {
      invoiceNumberRules: value => !!value || this.invoiceDate ? true : this.$t('general.rules.required')
    }
  },
  computed: {
    invoiceReferenceAsterisk () {
      return this.invoiceDate
        ? ''
        : ' *'
    }
  },
  watch: {
    invoiceDate (value) {
      if (value) {
        this.$refs.invoiceNumber.validate()
      }
    }
  }
}
</script>
